import React from "react"
import WorkUnitOverview from "./workUnitOverview"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"

import * as workStyles from "./work.module.css"

const query = graphql`
  {
    allFile(
      filter: { name: { in: ["KBC", "Mediamarkt", "BOTM", "Playar", "VitaS"] } }
      sort: { fields: name }
    ) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  }
`

const Work = () => {
  const data = useStaticQuery(query)

  const BOTMImage = data.allFile.nodes[0].childImageSharp.gatsbyImageData
  const KBCImage = data.allFile.nodes[1].childImageSharp.gatsbyImageData
  const MediamarktImage = data.allFile.nodes[2].childImageSharp.gatsbyImageData
  const PlayarImage = data.allFile.nodes[3].childImageSharp.gatsbyImageData
  const VitaSImage = data.allFile.nodes[4].childImageSharp.gatsbyImageData

  return (
    <section className="container">
      <h2 className="title">work.</h2>
      <div className={workStyles.gridcontainer}>
        <article>
          <Link to="/work/kbc" className={workStyles.clickable}>
            <WorkUnitOverview
              company="KBC"
              image={KBCImage}
              description="A series of animated explainer videos that explain the benefits of Microsoft Teams to the employees of KBC."
              page="work"
            />
            {/*             <Link to="/work/kbc" className={workStyles.link}>
              See full project &gt;
            </Link> */}
          </Link>
        </article>
        <article>
          <Link to="/work/mediamarkt" className={workStyles.clickable}>
            <WorkUnitOverview
              company="MediaMarkt"
              image={MediamarktImage}
              description="A fictional concept and animation for the  end-of-
year campaign of MediaMarkt."
              page="work"
            />
            {/*             <Link to="/work/mediamarkt" className={workStyles.link}>
              See full project &gt;
            </Link> */}
          </Link>
        </article>
        <article>
          <Link to="/work/botm" className={workStyles.clickable}>
            <WorkUnitOverview
              company="Boots On The Moon"
              image={BOTMImage}
              description="For six weeks, we co-founded our own pop-up
creative agency at the Belgian Advertising School."
              page="work"
            />
            {/*             <Link to="/work/botm" className={workStyles.link}>
              See full project &gt;
            </Link> */}
          </Link>
        </article>
        <article>
          <Link to="/work/playar" className={workStyles.clickable}>
            <WorkUnitOverview
              company="Playar Studio"
              image={PlayarImage}
              description="Concept creation and (motion) design for several projects in collaboration with Playar Studio."
              page="work"
            />
          </Link>
          {/*           <Link to="/work/playar" className={workStyles.link}>
            See full project &gt;
          </Link> */}
        </article>
        <article>
          <Link to="/work/vitas" className={workStyles.clickable}>
            <WorkUnitOverview
              company="VitaS"
              image={VitaSImage}
              description="A project based on helping residential care facilities to attract more employees by conducting research and delivering a promotional video."
              page="work"
            />
            {/* <Link to="/work/vitas" className={workStyles.link}>
              See full project &gt;
            </Link> */}
          </Link>
        </article>
      </div>
    </section>
  )
}

export default Work
