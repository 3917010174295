import React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Work from "../../components/work"

const index = () => {
  return (
    <Layout>
      <Seo title="Work" />
      <Work />
    </Layout>
  )
}

export default index
