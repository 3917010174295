import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

import * as workunitoverviewStyles from "./workUnitOverview.module.css"

const WorkUnitOverview = ({ company, image, description, page }) => {
  return (
    <div
      className={
        page === "workdetail"
          ? `${workunitoverviewStyles.container} ${workunitoverviewStyles.containerHorizontal}`
          : `${workunitoverviewStyles.container}`
      }
    >
      <GatsbyImage
        image={image}
        alt={company}
        className={
          page === "workdetail"
            ? `${workunitoverviewStyles.image} ${workunitoverviewStyles.imageHorizontal}`
            : `${workunitoverviewStyles.image}`
        }
      />
      <div
        className={
          page === "workdetail"
            ? `${workunitoverviewStyles.textContainer} ${workunitoverviewStyles.textContainerHorizontal}`
            : `${workunitoverviewStyles.textContainer}`
        }
      >
        <h3 className={workunitoverviewStyles.companyName}>{company}</h3>
        <p className={`description ${workunitoverviewStyles.mb0}`}>
          {description}
        </p>
      </div>
    </div>
  )
}

export default WorkUnitOverview
